import { useSelector } from "react-redux";
import { ACCESS_TOKEN_KEY, ON_BOARDING_STATUS } from "_common/constants";

export const AUTH_SET_AUTHENTICATED = "AUTH/SET_AUTHENTICATED";
export const AUTH_SET_USER = "AUTH/SET_USER";
export const AUTH_SET_ON_BOARDING_STATUS = "AUTH/SET_ON_BOARDING_STATUS";
export const AUTH_LOGOFF = "AUTH/LOGOFF";

export const AUTH_SET_ACTIVE_USER_MODAL_OPEN = "AUTH/SET_ACTIVE_USER_MODAL_OPEN";
export const AUTH_SET_ACTIVE_USER_MODAL_CLOSE = "AUTH/SET_ACTIVE_USER_MODAL_CLOSE";

export const AUTH_SET_INCOMPLETE_PROFILE_MODAL_OPEN = "AUTH/SET_INCOMPLETE_PROFILE_MODAL_OPEN";
export const AUTH_SET_INCOMPLETE_PROFILE_MODAL_CLOSE = "AUTH/SET_INCOMPLETE_PROFILE_MODAL_CLOSE";

export const AUTH_SET_HOUR_ADJUSTMENT_MODAL_OPEN = "AUTH/SET_HOUR_ADJUSTMENT_MODAL_OPEN";
export const AUTH_SET_HOUR_ADJUSTMENT_MODAL_CLOSE = "AUTH/SET_HOUR_ADJUSTMENT_MODAL_CLOSE";

export const AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_OPEN = "AUTH/UPDATE_TYPE_BANK_ACCOUNT_MODAL_OPEN";
export const AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_CLOSE = "AUTH/UPDATE_TYPE_BANK_ACCOUNT_MODAL_CLOSE";

const INITIAL_STATE = {
  authenticated: localStorage.getItem(ACCESS_TOKEN_KEY) ? true : false,
  user: null,
  activeUserModal: {
    open: false
  },
  incompleteProfileModal: {
    open: false
  },
  hourAdjustmentModal: {
    open: false
  },
  updateTypeBankAccountModal: {
    open: false
  },
};

const Auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_SET_AUTHENTICATED:
      return { ...state, authenticated: true };
    case AUTH_SET_USER: {
      const user = action.payload;
      user.on_boarding_status = user.on_boarding_status || ON_BOARDING_STATUS.WELCOME;
      return { ...state, ...{ user }, authenticated: true };
    }
    case AUTH_SET_ON_BOARDING_STATUS:
      return { ...state, user: { ...state.user, on_boarding_status: action.payload } };
    case AUTH_SET_ACTIVE_USER_MODAL_OPEN:
      return { ...state, activeUserModal: { ...state.activeUserModal, open: true } };
    case AUTH_SET_ACTIVE_USER_MODAL_CLOSE:
      return { ...state, activeUserModal: { ...state.activeUserModal, open: false } };
    case AUTH_SET_INCOMPLETE_PROFILE_MODAL_OPEN:
      return { ...state, incompleteProfileModal: { ...state.incompleteProfileModal, open: true } };
    case AUTH_SET_INCOMPLETE_PROFILE_MODAL_CLOSE:
      return { ...state, incompleteProfileModal: { ...state.incompleteProfileModal, open: false } };
    case AUTH_SET_HOUR_ADJUSTMENT_MODAL_OPEN:
      return { ...state, hourAdjustmentModal: { ...state.hourAdjustmentModal, open: true } };
    case AUTH_SET_HOUR_ADJUSTMENT_MODAL_CLOSE:
      return { ...state, hourAdjustmentModal: { ...state.hourAdjustmentModal, open: false } };
    case AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_OPEN:
      return { ...state, updateTypeBankAccountModal: { ...state.updateTypeBankAccountModal, open: true } };
    case AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_CLOSE:
      return { ...state, updateTypeBankAccountModal: { ...state.updateTypeBankAccountModal, open: false } };
    case AUTH_LOGOFF:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const useAuthUser = () => useSelector(({ auth }) => auth.user);
export const useAuthAuthenticated = () => useSelector(({ auth }) => auth.authenticated);
export const useAuthActiveUserModal = () => useSelector(({ auth }) => auth.activeUserModal);
export const useAuthIncompleteProfileModal = () => useSelector(({ auth }) => auth.incompleteProfileModal);
export const useAuthHourAdjustmentModal = () => useSelector(({ auth }) => auth.hourAdjustmentModal);
export const useAuthUpdateTypeBankAccountModal = () => useSelector(({ auth }) => auth.updateTypeBankAccountModal);

export default Auth;
