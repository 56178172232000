export default {
  label: {
    color: "#919fb3 !important",
    fontSize: "20px !important",
    fontWeight: "500 !important",
    fontFamily: '"Open Sans"',
  },
  completed: {
    color: "#04a2e8 !important",
  },
  active: {
    color: "#04a2e8 !important",
  },
};
