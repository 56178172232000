import palette from "../palette";

export default {
  tooltip: {
    backgroundColor: palette.navy70,
    color: "#FFFFFF",
    border: `1px solid ${palette.navy70}`,
    fontSize: 12
  },
  arrow: {
    color: palette.navy70
  }
};
