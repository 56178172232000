import React, { Suspense } from "react";

const LazyLoadingComponent = (Component) => {
  return (props) => (
    <Suspense fallback={<div />}>
      <Component {...props} />
    </Suspense>
  );
};

export default LazyLoadingComponent;
