import api from "./api";

const VIBBRANTES_URL = "/recruitment/vibbrantes";

const getUser = () => api.get(`${VIBBRANTES_URL}/0`);

const updateUser = (user) => api.put(`${VIBBRANTES_URL}/0`, user);

export default {
  getUser,
  updateUser
};
