/* eslint-disable */

Array.prototype.sortBy = function (field) {
  if (this) {
    return this.sort((a, b) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));
  }
  return this;
};

Array.prototype.groupBy = function () {};
