import reduxStore from "store";
import { ACCESS_TOKEN_KEY } from "_common/constants";
import api from "./api";

const AUTH_URL = "vibbrante/auth";

const isAuthenticated = () => reduxStore?.getState()?.auth?.authenticated;

const login = (email, password) => api.post(`${AUTH_URL}/sign_in`, { email, password });

const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem("chat");
  localStorage.removeItem("officialChat");
  window.location.reload();
};

const forgotPassword = (email) =>
  api.post(`${AUTH_URL}/password`, { email: email, redirect_url: `${process.env.REACT_APP_BASE_URL}/reset-password` });

const resetPassword = (data, headers) => api.put(`${AUTH_URL}/password`, data, { headers });

export default {
  isAuthenticated,
  login,
  logout,
  forgotPassword,
  resetPassword
};
