import { Box, Hidden, makeStyles } from "@material-ui/core";
import { useSystem } from "context/system";
import React, { useEffect } from "react";
import authService from "services/auth.service";
import { useAuthUser } from "store/reducers/auth";
import ErrorModal from "../errorModal";
import NavbarDesktop from "./navbarDesk";
import NavbarMobile from "./navbarMobile";

var moment = require("moment");
require("moment/min/locales.min");
moment.locale("pt-BR");

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      display: "flex"
    }
  },
  menuButton: {
    marginRight: 36
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  },
  dialogTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  modalTitle: {
    color: "#02284B",
    fontSize: 18,
    fontWeight: "700"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#02284B"
  },
  btnModal: {
    textTransform: "none",
    color: "#ffffff",
    width: 200,
    height: 50,
    fontSize: 18,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  boxModal: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: "#4D4F5C",
    width: 420
  }
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const { selectedOption, setSelectedOption, navbarMenu, setNavbarMenu } = useSystem();

  const authUser = useAuthUser();

  useEffect(() => {
    function optionCss() {
      let path = window.location.pathname;

      switch (path) {
        case `/recruitment/${authUser?.id}/home`:
          return setSelectedOption("home");
        case `/recruitment/${authUser?.id}/my-test`:
          return setSelectedOption("my-test");
        case `/recruitment/${authUser?.id}/calendar`:
          return setSelectedOption("calendar");
        case `/recruitment/${authUser?.id}/onboarding`:
          return setSelectedOption("onboarding");
        case `/recruitment/${authUser?.id}/appraiser`:
          return setSelectedOption("appraiser");
        case `/recruitment/${authUser?.id}/appraiser/pending-test/`:
          return setSelectedOption("appraiser");
        case `/recruitment/${authUser?.id}/profile`:
          return setSelectedOption("profile");
        case `/recruitment/${authUser?.id}/profile/edit`:
          return setSelectedOption("profile");
        default:
          return setSelectedOption("home");
      }
    }

    if (authService.isAuthenticated()) {
      optionCss();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption]);

  useEffect(() => {
    function showNavbarMenu() {
      setNavbarMenu(true);
    }

    if (authService.isAuthenticated()) {
      showNavbarMenu();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbarMenu]);

  return (
    <>
      <Box className={classes.root} mb={10}>
        <Hidden only="xs">
          <NavbarDesktop />
        </Hidden>

        <Hidden only={["sm", "md", "lg", "xl"]}>
          <NavbarMobile />
        </Hidden>

        <ErrorModal />

        <main className={classes.content}>
          <div className={classes.toolbar} />

          {props.routes}
        </main>
      </Box>
    </>
  );
}
