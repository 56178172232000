import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// TODO: Remover dependencia do bootstrap
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "store";
import Snackbar from "_common/components/Snackbar";
import "_common/prototypes";
import App from "./App";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

ReactDOM.render(
  <Provider {...{ store }}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    <Snackbar />
  </Provider>,
  document.getElementById("root")
);
