import React from "react";
import { useDispatch } from "react-redux";
import createAction from "store/createAction";
import { useHistory } from "react-router-dom";
import { AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_CLOSE, useAuthUpdateTypeBankAccountModal, useAuthUser } from "store/reducers/auth";
import UpdateTypeBankAccountModalView from "./UpdateTypeBankAccountModalView";

export default function UpdateTypeBankAccountModal() {
  const history = useHistory();
  const dispatch = useDispatch();

  const authUser = useAuthUser();
  const authUpdateTypeBankAccountModal = useAuthUpdateTypeBankAccountModal();

  const goToEditProfile = async () => {
    history.push(`/recruitment/${authUser?.id}/profile/edit`);
    dispatch(createAction(AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_CLOSE));
  };

  const handleClose = () => {
    dispatch(createAction(AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_CLOSE));
  };

  return <UpdateTypeBankAccountModalView {...{ authUpdateTypeBankAccountModal, handleClose, goToEditProfile }} />;
}
