import React, { useState } from "react";
import { useDispatch } from "react-redux";
import usersService from "services/users.service";
import createAction from "store/createAction";
import { AUTH_SET_ACTIVE_USER_MODAL_CLOSE, AUTH_SET_USER, useAuthActiveUserModal, useAuthUser } from "store/reducers/auth";
import useSnackbar from "_common/hooks/useSnackbar";
import ActiveUserModalView from "./ActiveUserModalView";
import { LOCAL_STORAGE_DONT_ASK_AGAIN_ACTIVE_USER } from "_common/constants";

export default function ActiveUserModal() {
  const dispatch = useDispatch();
  const { snackbarSuccess } = useSnackbar();

  const authUser = useAuthUser();
  const authActiveUserModal = useAuthActiveUserModal();

  const [loading, setLoading] = useState(false);

  const handleActiveProfile = async () => {
    try {
      setLoading(true);
      const { data } = await usersService.updateProfileUser({ ...authUser, status: "active" });

      dispatch(createAction(AUTH_SET_USER, data));
      snackbarSuccess();
      handleClose();
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (dontAskAgain) => {
    if (dontAskAgain) {
      localStorage.setItem(LOCAL_STORAGE_DONT_ASK_AGAIN_ACTIVE_USER, +new Date());
    }

    dispatch(createAction(AUTH_SET_ACTIVE_USER_MODAL_CLOSE));
  };

  return <ActiveUserModalView {...{ authActiveUserModal, handleClose, handleActiveProfile, loading }} />;
}
