import axios from "axios";
import authService from "services/auth.service";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // baseURL: "http://localhost:3001/api/v1",
    // baseURL: "http://ec2-52-14-12-138.us-east-2.compute.amazonaws.com:8090/api/v1",
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
    }
});

api.interceptors.request.use(async(config) => {
    if (localStorage.getItem("vibbra-headers") != null) {
        const authData = JSON.parse(localStorage.getItem("vibbra-headers"));
        config.headers.common["access-token"] = authData["access-token"];
        config.headers.common.client = authData.client;
        config.headers.common.expiry = authData.expiry;
        config.headers.common["token-type"] = "Bearer";
        config.headers.common.uid = authData.uid;
    }
    return config;
});

api.interceptors.response.use(
    function(response) {
        return response;
    },
    function({ response }) {
        const { config } = response;
        if (401 === response.status && !config.url.includes("/auth/sign_in")) {
            authService.logout();
        }
        return Promise.reject(response);
    }
);

export default api;

export const signUp = (name, lastname, email, password, password_confirmation, accepted_term, confirm_success_url) => {
    return api.post("vibbrante/auth", { name, lastname, email, password, password_confirmation, accepted_term, confirm_success_url });
};

// TODO: Remover essa função quando não for mais chamada
export const getUser = () => {
    return api.get(`/recruitment/vibbrantes/0`);
};

// export const updateUser = (data) => {
//   return api.put(`/recruitment/vibbrantes/0`, data);
// };

export const updateProfileUser = (data) => {
    return api.put("/vibbrante/users/0", { vibbrante: data });
};

export const getProfiles = () => {
    return api.get("/recruitment/profiles");
};

export const getProfile = (id) => {
    return api.get(`/recruitment/profiles/${id}`);
};

export const getNewChat = (chatId) => {
    return api.get(`/recruitment/chats/new?chat_id=${chatId}`);
};

export const getSkills = () => {
    return api.get("/recruitment/skills");
};

export const updateSkills = (skills) => {
    return api.put("/recruitment/vibbrantes/0/clt_skills", skills);
};

export const updateAnswers = (id, answers) => {
    return api.put(`/recruitment/chats/${id}`, answers);
};

export const postOnboarding = () => {
    return api.post(`/recruitment/vibbrante_onboardings`);
};

export const getOnboarding = () => {
    return api.get(`/recruitment/vibbrante_onboardings`);
};

export const updateOnboarding = (onboarding_id, data) => {
    return api.put(`/recruitment/vibbrante_onboardings/${onboarding_id}`, data);
};

export const postTests = () => {
    return api.post("/recruitment/vibbrante_tests");
};

export const updateTest = (test_id, test) => {
    return api.put(`/recruitment/vibbrante_tests/${test_id}`, test);
};

export const getMyTest = () => {
    return api.get(`/recruitment/vibbrante_tests/current`);
};

export const getMyJourney = () => {
    return api.get(`/vibbrante/users/my_journey`);
};

export const getNotifications = () => {
    return api.get("/vibbrante/notifications");
};

export const getInterviewer = () => {
    return api.get("/recruitment/interviewers");
};

export const getSchedule = () => {
    return api.get("/recruitment/vibbrantes/schedules");
};

export const createSchedule = (params) => {
    return api.post("/recruitment/vibbrantes/schedule", params);
}

export const getOrigin = () => {
    return api.get("/recruitment/vibbrantes/origins");
};

export const getTags = () => {
    return api.get("/vibbrante/list/tags");
};

export const createVibbranteSkill = (data) => {
    return api.post("/vibbrante/vibbrante_skills", { vibbrante_skill: data });
};

export const deleteVibbranteSkill = (id) => {
    return api.delete(`/vibbrante/vibbrante_skills/${id}`);
};

export const checkCpf = (cpf) => {
    return api.get(`/vibbrante/users/check_cpf?cpf=${cpf}`);
};

export const getPendingTests = () => {
    return api.get("/appraiser/vibbrante_tests/pendings");
};

export const getPendingTest = (id) => {
    return api.get(`/appraiser/vibbrante_tests/${id}`);
};

export const updatePendingTest = (id, data) => {
    return api.put(`/appraiser/vibbrante_tests/${id}`, data);
};

export const updatePendingStatusTest = (id) => {
    return api.put(`/appraiser/vibbrante_tests/${id}/update_status`, null);
};

export const updatePendingScoreTest = (id, data) => {
    return api.put(`/appraiser/vibbrante_tests/${id}/update_scores`, data);
};
