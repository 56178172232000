import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import RedirectProjectProvider from "context/redirectProject";
import StepProvider from "context/steps";
import TestDeveloperProvider from "context/testDeveloper";
import React, { lazy, useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Route, Switch, useHistory } from "react-router-dom";
import EventEmitter from "reactjs-eventemitter";
import { useAuthUser } from "store/reducers/auth";
import LazyLoadingComponent from "_common/components/LazyLoadingComponent";
import PrivateRoute from "_common/components/PrivateRoute";

const SignIn = LazyLoadingComponent(lazy(() => import("pages/signin")));
const SignUp = LazyLoadingComponent(lazy(() => import("pages/signup")));
const InternalSignUp = LazyLoadingComponent(lazy(() => import("pages/internal-signup")));
const ForgotPassword = LazyLoadingComponent(lazy(() => import("pages/forgotPassword")));
const EditPassword = LazyLoadingComponent(lazy(() => import("pages/forgotPassword/editPassword")));

const Expertise = LazyLoadingComponent(lazy(() => import("pages/expertise")));
const Welcome = LazyLoadingComponent(lazy(() => import("pages/welcome")));
const Nerd = LazyLoadingComponent(lazy(() => import("pages/Nerd")));

const RecruitmentFullstack = LazyLoadingComponent(lazy(() => import("pages/fullstack")));
const RecruitmentVibbranteInfo = LazyLoadingComponent(lazy(() => import("pages/vibbrante/vibbranteInfo")));
const RecruitmentChatbot = LazyLoadingComponent(lazy(() => import("pages/vibbrante/chatbot")));
const RecruitmentOnboardingFreelancer = LazyLoadingComponent(lazy(() => import("pages/calendar")));

const RecruitmentHome = LazyLoadingComponent(lazy(() => import("pages/home")));
const RecruitmentMyTest = LazyLoadingComponent(lazy(() => import("pages/myTest")));
const RecruitmentCalendar = LazyLoadingComponent(lazy(() => import("pages/myCalendar")));
const RecruitmentProfile = LazyLoadingComponent(lazy(() => import("pages/profile")));
const RecruitmentEditProfile = LazyLoadingComponent(lazy(() => import("pages/profile/edit")));
const RecruitmentOnboarding = LazyLoadingComponent(lazy(() => import("pages/myOnboarding")));
const RecruitmentAppraiser = LazyLoadingComponent(lazy(() => import("pages/appraiser")));
const RecruitmentPendingTest = LazyLoadingComponent(lazy(() => import("pages/pendingTest")));

const useStyles = makeStyles((theme) => ({
  Container: {
    padding: theme.spacing(0),
    backgroundColor: "#F9FBFD",
    borderRadius: "7px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    }
  }
}));

function Routes() {
  const history = useHistory();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const authUser = useAuthUser();

  useEffect(() => {
    EventEmitter.subscribe("startLoading", (event) => {
      setLoading(true);
    });

    EventEmitter.subscribe("endLoading", (event) => {
      setLoading(false);
    });

    if (authUser?.vibbrante_type === "nerd") {
      history.push("/nerd");
    }
  }, [authUser, history]);

  return (
    <LoadingOverlay
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.5)"
        })
      }}
      active={loading}
      spinner={<CircularProgress thickness={1} size={80} />}>
      <Container className={classes.Container}>
        <Switch>
          <RedirectProjectProvider>
            <Route exact path="/" component={SignIn} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/protected/i/v/r" component={InternalSignUp} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={EditPassword} />
            <PrivateRoute exact path="/nerd" component={Nerd} />

            <PrivateRoute path="/expertise" component={Expertise} />
            <PrivateRoute path="/welcome" component={Welcome} />

            <StepProvider>
              <PrivateRoute exact path="/recruitment/:id" component={RecruitmentVibbranteInfo} />
              <PrivateRoute path="/recruitment/:id/onboarding-freelancer" component={RecruitmentOnboardingFreelancer} />
              <TestDeveloperProvider>
                <PrivateRoute path="/recruitment/:id/chat" component={RecruitmentChatbot} />
                <PrivateRoute path="/recruitment/:id/test" component={RecruitmentFullstack} />
              </TestDeveloperProvider>
            </StepProvider>

            <PrivateRoute path="/recruitment/:id/home" component={RecruitmentHome} />
            <PrivateRoute path="/recruitment/:id/my-test" component={RecruitmentMyTest} />
            <PrivateRoute path="/recruitment/:id/calendar" component={RecruitmentCalendar} />

            <PrivateRoute exact path="/recruitment/:id/profile" component={RecruitmentProfile} />
            <PrivateRoute path="/recruitment/:id/profile/edit" component={RecruitmentEditProfile} />

            <PrivateRoute path="/recruitment/:id/onboarding" component={RecruitmentOnboarding} />
            <PrivateRoute exact path="/recruitment/:id/appraiser" component={RecruitmentAppraiser} />
            <PrivateRoute path="/recruitment/:id/appraiser/pending-test/:id" component={RecruitmentPendingTest} />
          </RedirectProjectProvider>
        </Switch>
      </Container>
    </LoadingOverlay>
  );
}

export default Routes;