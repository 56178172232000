export const isAuthenticated = () => localStorage.getItem("vibbra-headers") !== null;

export const getHeaders = () => {
  var headers = JSON.parse(localStorage.getItem("vibbra-headers"));
  return headers;
};

export const updateHeadersLocalStorage = (headers) => {
  localStorage.setItem("vibbra-headers", JSON.stringify(headers));
};

export const getChat = () => {
  var chat = JSON.parse(localStorage.getItem("chat"));
  return chat;
};

export const updateOnboardingStatus = (status) => {
  localStorage.setItem("on_boarding_status", JSON.stringify(status));
};
