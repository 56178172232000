import { Box, Button, createStyles, Dialog, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ErrorImage from "assets/images/finish_time.png";
import { useSystem } from "context/system";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      textAlign: "center",
      color: "#1F2238"
    },
    titleModal: {
      fontSize: 32,
      fontWeight: "bold"
    },
    subtitleModal: {
      fontSize: 20,
      marginBottom: "3%"
    },
    textModal: {
      fontSize: 16,
      fontWeight: "100",
      marginBottom: theme.spacing(4)
    },
    btnModal: {
      width: 200,
      height: 50,
      textTransform: "none",
      color: "#ffffff"
    },
    btnModalDiv: {
      textAlign: "center"
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(2),
      color: "#02284B"
    },
    imageResponsive: {
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(0),
        width: "80%",
        height: "auto"
      }
    }
  })
);

export default function ErrorModal(props) {
  const classes = useStyles();
  const { showErrorModal, setShowErrorModal } = useSystem();

  const handleClose = () => setShowErrorModal(false);

  return (
    <Dialog
      aria-labelledby="max-width-dialog-title"
      open={showErrorModal}
      className={classes.modal}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Box mt={3} mb={4}>
        <img src={ErrorImage} alt="Vibbra" className={classes.imageResponsive} />
      </Box>

      <Box mt={2} className={classes.titleModal}>
        Oops!!!
      </Box>

      <Box mt={2} pb={3} className={classes.subtitleModal}>
        Tivemos um probleminha aqui e vamos verificar para corrigir.
      </Box>

      <Box mt={2} pb={3} ml={8} mr={8}>
        Por agora, recomendamos que recarregue a página ou tente deslogar e logar novamente para ver se ainda ocorre. Caso veja novamente
        essa mensagem, pedimos que entre em contato conosco através do email <b>suporte@vibbra.com.br</b>
        <span>&nbsp;</span>
        <br />
        relatando o que ocorreu que vamos resolver!
      </Box>

      <Box pb={3} className={classes.btnModalDiv}>
        <Button variant="contained" color="primary" className={classes.btnModal} onClick={handleClose}>
          Entendi
        </Button>
      </Box>
    </Dialog>
  );
}
