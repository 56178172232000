import { useSystem } from "context/system";
import React, { createContext, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { postTests, updateAnswers, updateTest } from "services/api";
import authService from "services/auth.service";
import { useAuthUser } from "store/reducers/auth";

const TestDeveloperContext = createContext();

export default function TestDeveloperProvider({ children }) {
  const history = useHistory();

  const authUser = useAuthUser();

  const [openInformation, setOpenInformation] = useState(true);
  const [selectedTest, setSelectedTest] = useState(null);
  const [instructions, setInstructions] = useState(null);
  const [missions, setMissions] = useState(null);
  const [scopes, setScopes] = useState(null);
  const [checklist, setChecklist] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [continueTextVivian, setContinueTextVivian] = useState(false);
  const [onBoardingStatus, setOnboardingStatus] = useState("");
  const [errorTestModal, setErrorTestModal] = useState(false);
  const { setShowErrorModal, sentryError, setShowAlert } = useSystem();

  useEffect(() => {
    async function fetchData() {
      try {
        // TODO: Entender melhor esse bloco de código para em seguida remover a chamada do getUser()

        if (authUser.on_boarding_status === "test") {
          localStorage.removeItem("callTestAgain");
          handleSelectedTest();
        }
      } catch (error) {
        setStartDate(10 * 60 * 60 * 1000);
        setErrorTestModal(true);
        if (
          authService.isAuthenticated() &&
          (localStorage.getItem("callTestAgain") === null ||
            localStorage.getItem("callTestAgain") === "1" ||
            localStorage.getItem("callTestAgain") === "2")
        ) {
          if (localStorage.getItem("callTestAgain") === null) {
            localStorage.setItem("callTestAgain", 1);
          }

          if (authUser?.on_boarding_status === "test") {
            if (localStorage.getItem("callTestAgain") === "1") {
              setTimeout(() => {
                localStorage.setItem("callTestAgain", 2);
                history.push(`/recruitment/${authUser?.id}/test`);
              }, 5000);
            } else if (localStorage.getItem("callTestAgain") === "2") {
              setTimeout(() => {
                localStorage.setItem("callTestAgain", 3);
                history.push(`/recruitment/${authUser?.id}/test`);
              }, 5000);
            }
          }
        } else {
          if (error.status !== 401) {
            setShowAlert(true);
          }
        }
      }
    }

    if (selectedTest === null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedTest = async () => {
    try {
      const { data } = await postTests();

      ReactDOM.unstable_batchedUpdates(() => {
        setSelectedTest(data);
        setInstructions(data.test_template.instructions);
        setMissions(orderList(data.test_template.missions));
        setScopes(data.test_template.scopes);
        setChecklist(orderList(data.checklists_attributes));
        setTasks(data.tasks_attributes);
        setStartDate(new Date(data.start_date).getTime() + data.duration * 60 * 60 * 1000);
      });

      if (data.status === "waiting_accepted_term") {
        updateStatusTest(data.id);
      }
    } catch (error) {
      if (error.status === 404) {
        chatBackend();
      } else {
        sentryError(error);
        console.log(error);
        if (authService.isAuthenticated()) {
          setShowErrorModal(true);
          handleSelectedTest();
        } else {
          setShowAlert(true);
        }
      }
    }
  };

  const chatBackend = async () => {
    const data = JSON.parse(localStorage.getItem("chat"));
    const chat_answer = {
      chat_answer: data
    };

    try {
      const { status } = await updateAnswers(data.id, chat_answer);
      if (status === 200) {
        handleSelectedTest();
      }
    } catch (error) {
      sentryError(error);
      console.log(error);
      setShowErrorModal(true);
    }
  };

  const updateStatusTest = async (id) => {
    try {
      await updateTest(id, { status: "started" });
      handleSelectedTest();
    } catch (error) {
      sentryError(error);
      console.log(error);
    }
  };

  const orderList = (list) => list.sort((a, b) => a.sequence - b.sequence);

  return (
    <TestDeveloperContext.Provider
      value={{
        openInformation,
        setOpenInformation,
        selectedTest,
        instructions,
        scopes,
        checklist,
        missions,
        tasks,
        handleSelectedTest,
        startDate,
        loading,
        setLoading,
        // TODO: Revisar onBoardingStatus
        onBoardingStatus,
        setOnboardingStatus,
        continueTextVivian,
        setContinueTextVivian,
        setStartDate,
        errorTestModal,
        setErrorTestModal
      }}>
      {children}
    </TestDeveloperContext.Provider>
  );
}

export function useTestDeveloper() {
  const context = useContext(TestDeveloperContext);

  const {
    openInformation,
    setOpenInformation,
    selectedTest,
    instructions,
    scopes,
    checklist,
    missions,
    tasks,
    handleSelectedTest,
    startDate,
    loading,
    setLoading,
    onBoardingStatus,
    continueTextVivian,
    setContinueTextVivian,
    setStartDate,
    errorTestModal,
    setErrorTestModal
  } = context;
  return {
    openInformation,
    setOpenInformation,
    selectedTest,
    instructions,
    scopes,
    checklist,
    missions,
    tasks,
    handleSelectedTest,
    startDate,
    loading,
    setLoading,
    onBoardingStatus,
    continueTextVivian,
    setContinueTextVivian,
    setStartDate,
    errorTestModal,
    setErrorTestModal
  };
}
