import React, { useEffect, useState } from "react";
import { useAuthUser } from "store/reducers/auth";
import { useSystem } from "../../context/system";

export default function MyAvatar(props) {
  const [avatar, setAvatar] = useState(null);
  const { myAvatar } = useSystem();

  const authUser = useAuthUser();

  useEffect(() => {
    function changeAvatar() {
      if (myAvatar && !myAvatar.includes("/0.svg")) {
        setAvatar(require(`../../assets/avatars/${myAvatar}`));
      } else {
        setAvatar(require(`../../assets/avatars/0.svg`));
      }
    }

    if (myAvatar !== undefined && myAvatar !== null) {
      changeAvatar();
    }
  }, [myAvatar]);

  return <img src={avatar} alt={authUser.name} width={props.width} height={props.height} />;
}
