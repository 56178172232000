import palette from "../palette";
import typography from "../typography";

export default {
  "@global": {
    html: {
      fontSize: typography.htmlFontSize
    },
    body: {
      margin: 0,
      backgroundColor: palette.background.default,
      fontFamily: typography.fontFamily
    },
    "#root": {
      backgroundColor: palette.background.default,
      position: "fixed",
      height: "100%",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      overflow: "auto"
    },
    "::-webkit-scrollbar": {
      width: 10,
      backgroundColor: "#F5F5F5"
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: palette.primary.main
    }
  }
};
