import React from "react";
import { Redirect, Route } from "react-router-dom";
import authService from "services/auth.service";

const PrivateRoute = ({ component: Component, ...resource }) => {
  const isAuthenticated = authService.isAuthenticated();

  return (
    <Route
      {...resource}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      }
    />
  );
};

export default PrivateRoute;
