import {
  AppBar,
  Avatar,
  Box,
  ClickAwayListener,
  Container,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "assets/images/logo_vibbra_white.svg";
import LogoBlue from "assets/images/logo_vibbra_color.svg";
import Background from "assets/images/milestone_wave.png";
import clsx from "clsx";
import React, { Fragment, useEffect, useRef, useState } from "react";
import authService from "services/auth.service";
import { useAuthUser } from "store/reducers/auth";
import { ON_BOARDING_STATUS } from "_common/constants";
import MyAvatar from "../myAvatar";
import MenuLinks from "./menuLinks";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundImage: `url(${Background})`,
    backgroundPosition: "46% bottom",
    backgroundRepeat: "no-repeat",
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
    // [theme.breakpoints.down('sm')]: {
    //   display: 'none'
    // }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0)
    }
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  logoSize: {
    height: "40px"
  },
  menuIcon: {
    width: "25px",
    height: "24px",
    color: "#ffffff"
  },
  menuDivider: {
    width: "151px",
    marginLeft: "auto",
    marginRight: "auto"
  },
  menuList: {
    color: "#172B4D",
    fontSize: "12px",
    marginTop: theme.spacing(1.5)
  },
  paperMenu: {
    justifyContent: "center",
    width: "190px",
    height: "43px"
  },
  terms: {
    color: "#02284B",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
      color: "#02284B"
    }
  },
  listNotification: {
    width: "390px",
    maxWidth: "60ch",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    display: "inline",
    fontSize: "12px",
    fontWeight: "bold"
  },
  notificationBody: {
    fontSize: "12px",
    color: "#4D4F5C"
  },
  subheaderNotification: {
    position: "relative",
    fontSize: "18px"
  },
  footerNotification: {
    textAlign: "center",
    color: "#04A2E8",
    fontSize: "13px"
  },
  avatarNavbar: {
    width: 33,
    height: 33,
    cursor: "pointer"
  },
  popperClass: {
    marginTop: "10px",
    zIndex: "1",
    width: "199px",
    height: "196px"
  },

  vibbranteType: {
    color: "#8BC53F",
    paddingLeft: theme.spacing(0.5),
    textTransform: "capitalize"
  },
  notificationIcon: {
    fontSize: 16
  },
  dialogTitle: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  modalTitle: {
    color: "#02284B",
    fontSize: 18,
    fontWeight: "700"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#02284B"
  },
  btnModal: {
    textTransform: "none",
    color: "#ffffff",
    width: 200,
    height: 50,
    fontSize: 18,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  boxModal: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: "#4D4F5C",
    width: 420
  },
  nameNavbar: {
    cursor: "pointer"
  }
}));

export default function NavbarDesktop() {
  const classes = useStyles();

  const authUser = useAuthUser();

  const freelancerRealStatus = authUser?.choice === 'freelancer' && ['scheduled_interview', 'ready_to_activate', 'active'].includes(authUser.real_status)
  const onBoardingStatusIsReleaseOrComplete =
    authUser && ([ON_BOARDING_STATUS.RELEASE, ON_BOARDING_STATUS.COMPLETE].includes(authUser.on_boarding_status) || freelancerRealStatus);

  const [openDrawer, setOpenDrawer] = useState(onBoardingStatusIsReleaseOrComplete);
  const [openMenu, setOpenMenu] = useState(false);
  const [hideWhiteLogo, setHideWhiteLogo] = useState(onBoardingStatusIsReleaseOrComplete);
  const openMenuRef = useRef(null);

  useEffect(() => {
    setOpenDrawer(onBoardingStatusIsReleaseOrComplete);
  }, [onBoardingStatusIsReleaseOrComplete]);

  const handleToggleDrawerOpen = () => {
    setOpenDrawer(!openDrawer);
    setHideWhiteLogo(!hideWhiteLogo);
  };

  const handleToggleMenu = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event) => {
    if (openMenuRef.current && openMenuRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const exitSystem = () => authService.logout();

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openDrawer
        })}>
        <Toolbar>
          {onBoardingStatusIsReleaseOrComplete && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleToggleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, openDrawer && classes.hide)}>
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          )}

          {!authUser && (
            <>
              <Box component="span" ml={0} display={{ xs: "block", sm: "block", md: "none", lg: "none", xl: "none" }} />
              <Box component="span" ml={15} display={{ xs: "none", sm: "none", md: "none", lg: "block", xl: "block" }} />
            </>
          )}

          <Container>
            <Box display="flex">
              {!hideWhiteLogo && (
                <Box flexGrow={1}>
                  <img src={Logo} alt="Logo da Vibbra" className={classes.logoSize} />
                </Box>
              )}

              {hideWhiteLogo && <Box flexGrow={1} />}

              {authUser && (
                <Fragment>
                  <Hidden only={["xs"]}>
                    <Box justifyContent="flex-end" mr={10}>
                      <Box display="flex" justifyContent="flex-end">
                        <Box p={1} display="flex" alignItems="center">
                          <Avatar
                            ref={openMenuRef}
                            aria-controls={openMenu ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggleMenu}
                            className={classes.avatarNavbar}>
                            <MyAvatar width={33} height={33} />
                          </Avatar>
                        </Box>
                        <Box p={1} display="flex" alignItems="center">
                          <Popper
                            open={openMenu}
                            anchorEl={openMenuRef.current}
                            transition
                            placement="bottom"
                            modifiers={{
                              flip: {
                                enabled: true
                              },
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: "scrollParent"
                              },
                              arrow: {
                                enabled: true,
                                element: openMenuRef.current
                              }
                            }}>
                            <Paper>
                              <ClickAwayListener onClickAway={handleCloseMenu}>
                                <MenuList
                                  autoFocusItem={openMenu}
                                  id="menu-list-grow"
                                  onKeyDown={handleListKeyDown}
                                  className={classes.menuList}>
                                  <MenuItem onClick={handleCloseMenu} className={classes.paperMenu}>
                                    <b>Perfil: </b>
                                    <Box component="span" className={classes.vibbranteType}>
                                      {authUser.vibbrante_type}
                                    </Box>
                                  </MenuItem>
                                  <Divider className={classes.menuDivider} light={true} />
                                  <MenuItem onClick={exitSystem} className={classes.paperMenu}>
                                    Sair
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Popper>
                          <span ref={openMenuRef} onClick={handleToggleMenu} className={classes.nameNavbar}>
                            {authUser.name}
                          </span>
                        </Box>
                      </Box>
                    </Box>
                  </Hidden>
                </Fragment>
              )}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>

      {onBoardingStatusIsReleaseOrComplete && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openDrawer,
              [classes.drawerClose]: !openDrawer
            })
          }}>
          <div>
            <Box display="flex">
              <Box flexGrow={1} ml={3} pb={3}>
                <img src={LogoBlue} alt="Logo da Vibbra" className={classes.logoSize} />
              </Box>
              <Box>
                <IconButton onClick={handleToggleDrawerOpen}>{<MenuIcon color="primary" />}</IconButton>
              </Box>
            </Box>
          </div>
          <MenuLinks />
        </Drawer>
      )}
    </>
  );
}
