import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import BookOutlinedIcon from "@material-ui/icons/BookOutlined";
import CloseIcon from "@material-ui/icons/Close";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DescriptionIcon from "@material-ui/icons/Description";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { useSystem } from "context/system";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { getPendingTests } from "services/api";
import { getHeaders } from "services/auth";
import authService from "services/auth.service";
import { useAuthUser } from "store/reducers/auth";
import useCheckProfileComplete from "_common/hooks/useCheckProfileComplete";

const useStyles = makeStyles((theme) =>
  createStyles({
    selectedOption: {
      backgroundColor: "#ECFBFF",
      borderLeft: "5px solid #04A2EA"
    },
    notSelectedOption: {
      borderLeft: "5px solid #ffffff"
    },
    selectedIcon: {
      color: "#04A2EA"
    },
    falseDisabled: {
      opacity: 0.5
    },
    alternativeIcon: {
      width: "1.5em",
      height: "1.5em"
    }
  })
);

export default function MenuLinks() {
  const classes = useStyles();
  const { selectedOption } = useSystem();
  const [openInterviewWarning, setOpenInterviewWarning] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [textModal, setTextModal] = useState("");
  const [showAppraiser, setShowAppraiser] = useState(false);

  const authUser = useAuthUser();

  const { profileComplete, profileErrors } = useCheckProfileComplete(authUser);

  useEffect(() => {
    async function verifyTests() {
      try {
        try {
          await getPendingTests().then((res) => {
            if (res.data.length > 0) {
              setShowAppraiser(authUser.is_appraiser);
            }
          });
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (authService.isAuthenticated() && authUser.is_appraiser) {
      verifyTests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openContracts = () => {
    const header = getHeaders();

    let queryString = "?";
    queryString += "accepted_term=" + authUser["accepted_term"];
    queryString += "&name=" + authUser["name"];
    queryString += "&email=" + authUser["email"];

    queryString += "&access-token=" + header["access-token"];
    queryString += "&client=" + header["client"];
    queryString += "&expiry=" + header["expiry"];
    queryString += "&uid=" + header["uid"];

    window.open(process.env.REACT_APP_OLD_APP + "/#/external-auth" + queryString, "_self");
  };

  const interviewConducted = (menuItem) => {
    if (menuItem === "calendar") {
      if (authUser.vibbrante_type === "vibbrante") {
        setTitleModal("Entrevista realizada");
        setTextModal("Entrevista já realizada");
      } else {
        setTitleModal("Entrevista");
        setTextModal("Entrevista somente para candidatos a Vibbrantes e que passaram no teste técnico.");
      }
    } else {
      setTitleModal("Contratos");
      let errorsContract = "<ul>";

      Object.values(profileErrors).forEach((error, index) => {
        if (error !== "") {
          errorsContract = errorsContract + `<li>${error}</li>`;
        }
      });

      errorsContract = `${errorsContract}</ul>`;

      setTextModal(
        `Seu perfil precisa estar totalmente preenchido para ser ativado. Ou seja, você precisa preencher as seguintes informações: ${errorsContract}`
      );
    }

    setOpenInterviewWarning(true);
  };

  const exitSystem = () => authService.logout();

  const handleCloseInterview = () => {
    setOpenInterviewWarning(false);
  };

  const termsLink = (
    <a href="https://app.vibbra.com.br/#/terms" className={classes.terms} rel="noopener noreferrer" target="_blank">
      Termos de uso
    </a>
  );

  return (
    <>
      <BrowserRouter>
        {
          authService.isAuthenticated() &&
          ((authUser.on_boarding_status !== "complete" && authUser.on_boarding_status !== "release")
            && (authUser.choice !== 'freelancer' && !(['scheduled_interview', 'ready_to_activate', 'active'].includes(authUser.real_status)))) && (
          <List>
            <ListItem>
              <ListItemIcon>
                <BookOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{termsLink}</ListItemText>
            </ListItem>
          </List>
        )}

        {authService.isAuthenticated()
          && (((authUser.on_boarding_status === "complete") || (authUser.on_boarding_status === "release"))
            || (authUser.choice === 'freelancer' && ['scheduled_interview', 'ready_to_activate', 'active'].includes(authUser.real_status))) && (
          <List>
            <ListItem
              button
              component={"a"}
              href={`/recruitment/${authUser?.id}/home`}
              className={selectedOption === "home" ? classes.selectedOption : classes.notSelectedOption}>
              <ListItemIcon className={selectedOption === "home" ? classes.selectedIcon : ""}>
                <HomeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem
              button
              component={"a"}
              href={`/recruitment/${authUser?.id}/my-test`}
              className={selectedOption === "my-test" ? classes.selectedOption : classes.notSelectedOption}>
              <ListItemIcon className={selectedOption === "my-test" ? classes.selectedIcon : ""}>
                <DescriptionIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Teste" />
            </ListItem>
            {
              ((authUser.on_boarding_status === "complete")
              || (authUser.choice === "freelancer" && ['scheduled_interview', 'ready_to_activate', 'active'].includes(authUser.real_status))) && (
              <ListItem
                button
                component={"a"}
                href={`/recruitment/${authUser?.id}/calendar`}
                className={selectedOption === "calendar" ? classes.selectedOption : classes.notSelectedOption}>
                <ListItemIcon className={selectedOption === "calendar" ? classes.selectedIcon : ""}>
                  <DateRangeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Entrevista" />
              </ListItem>
            )}

            {
              ((authUser.on_boarding_status === "release")
              && !(authUser.choice === "freelancer" && ['scheduled_interview', 'ready_to_activate', 'active'].includes(authUser.real_status))) && (
              <ListItem
                button
                className={`${classes.notSelectedOption} ${classes.falseDisabled}`}
                onClick={() => interviewConducted("calendar")}>
                <ListItemIcon className={selectedOption === "calendar" ? classes.selectedIcon : ""}>
                  <DateRangeIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Entrevista" />
              </ListItem>
            )}

            {authUser.on_boarding_status !== "release" && !profileComplete && (
              <ListItem
                button
                className={`${classes.notSelectedOption} ${classes.falseDisabled}`}
                onClick={() => interviewConducted("contract")}>
                <ListItemIcon>
                  <AssignmentIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Contratos" />
              </ListItem>
            )}

            {authUser.on_boarding_status === "release" && !profileComplete && (
              <ListItem
                button
                className={`${classes.notSelectedOption} ${classes.falseDisabled}`}
                onClick={() => interviewConducted("contract")}>
                <ListItemIcon>
                  <AssignmentIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Contratos" />
              </ListItem>
            )}

            {authUser.on_boarding_status === "release" && profileComplete && (
              <ListItem button onClick={() => openContracts()} className={classes.notSelectedOption}>
                <ListItemIcon>
                  <AssignmentIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Contratos" />
              </ListItem>
            )}

            <ListItem
              button
              component={"a"}
              href={`/recruitment/${authUser?.id}/onboarding`}
              className={selectedOption === "onboarding" ? classes.selectedOption : classes.notSelectedOption}
              disabled={authUser.on_boarding_status !== "release"}>
              <ListItemIcon className={selectedOption === "onboarding" ? classes.selectedIcon : ""}>
                <SupervisedUserCircleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Onboarding" />
            </ListItem>

            {showAppraiser && (
              <ListItem
                button
                component={"a"}
                href={`/recruitment/${authUser?.id}/appraiser`}
                className={selectedOption === "appraiser" ? classes.selectedOption : classes.notSelectedOption}>
                <ListItemIcon className={selectedOption === "appraiser" ? classes.selectedIcon : ""}>
                  <AssignmentTurnedInIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Avaliador" />
              </ListItem>
            )}

            <ListItem
              button
              component={"a"}
              href={`/recruitment/${authUser?.id}/profile`}
              className={selectedOption === "profile" ? classes.selectedOption : classes.notSelectedOption}>
              <ListItemIcon className={selectedOption === "profile" ? classes.selectedIcon : ""}>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Perfil" />
            </ListItem>
          </List>
        )}

        <List>
          <Divider light />
          <ListItem onClick={() => exitSystem()}>
            <ListItemIcon>
              <ExitToAppIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
      </BrowserRouter>

      <Dialog open={openInterviewWarning} onClose={handleCloseInterview} maxWidth="xs">
        <DialogTitle className={classes.dialogTitle}>
          <span className={classes.modalTitle}>{titleModal}</span>
          <IconButton aria-label="close" className={`${classes.closeButton} ${classes.dialogTitle}`} onClick={handleCloseInterview}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <Box pb={3}>
          <Divider />
        </Box>
        <DialogContent>
          <Box className={classes.boxModal} pr={5}>
            <Box pb={3}>
              <Box component="span" dangerouslySetInnerHTML={{ __html: textModal }} />
            </Box>
          </Box>
        </DialogContent>
        <Box pb={2}>
          <Divider />
        </Box>
        <DialogActions>
          <Button onClick={handleCloseInterview} variant="contained" color="primary" className={classes.btnModal}>
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
