export default {
  lineVertical: {
    minHeight: "50px !important",
    borderLeftStyle: "solid",
    borderLeftWidth: "1px",
    borderColor: "#bdbdbd",
  },
  line: {
    display: "block",
    borderColor: "#bdbdbd",
    marginLeft: "2%",
  },
  vertical: {
    padding: "0 0 0 0 !important",
  },
};
