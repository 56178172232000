import { ptBR } from "@material-ui/core/locale";
import { createMuiTheme } from "@material-ui/core/styles";
import overrides from "./overrides";
import palette from "./palette";
import props from "./props";
import typography from "./typography";

const theme = createMuiTheme({ overrides, typography, palette, props }, ptBR);

if (process.env.NODE_ENV === "development") {
  window.theme = theme;
}

export default theme;
