export const ACCESS_TOKEN_KEY = "vibbra-headers";
export const LOCAL_STORAGE_REMEMBER_EMAIL_KEY = "remember-email-login";
export const LOCAL_STORAGE_DONT_ASK_AGAIN_ACTIVE_USER = "dont-ask-again-active-user";
export const LOCAL_STORAGE_REMINDER_SEEN_HOUR_ADJUSTMENT = "reminder-seen-hour-adjustment";
export const LOCAL_STORAGE_REMEMBER_LATER_HOUR_ADJUSTMENT = "remember-later-hour-adjustment";

export const ON_BOARDING_STATUS = {
    WELCOME: "welcome",
    EXPERTISE: "expertise",
    INFO: "info",
    CHAT: "chat",
    ONBOARDING_FREELANCER: "onboarding-freelancer",
    TEST: "test",
    COMPLETE: "complete",
    RELEASE: "release"
};

export const REAL_STATUS = {
    TEST_IN_CORRECTION: "test_in_correction",
    WAIT_INTERVIEW: "wait_interview",
    SCHEDULED_INTERVIEW: "scheduled_interview",
    READY_TO_ACTIVATE: "ready_to_activate",
    ACTIVE: "active",
};