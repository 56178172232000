import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

export default function HourAdjustmentModalView({ authHourAdjustmentModal, handleClose, rememberMeLater }) {
  return (
    <Dialog open={authHourAdjustmentModal.open} onClose={handleClose}>
      <DialogTitle>Lembrete</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Estar com as horas disponíveis atualizadas facilita o recebimento de novos contratos. Que tal ajustar as suas horas disponíveis?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={rememberMeLater} autoFocus>
          Obrigado por avisar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
