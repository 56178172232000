export default {
  root: {
    color: "#ffffff !important",
    display: "block",
  },
  completed: {
    width: "37px",
    height: "37px",
    boxShadow: "#04a2e829",
    marginBottom: "1px !important",
  },
  active: {
    height: "37px",
    width: "37px",
    boxShadow: "#04a2e829",
  },
  text: {
    fill: "#ffffff !important",
    fontSize: "0.75rem",
  },
};
