import { AppBar, Box, CssBaseline, Drawer, IconButton, makeStyles, Toolbar, useTheme } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "assets/images/logo_vibbra_white.svg";
import LogoBlue from "assets/images/logo_vibbra_color.svg";
import Background from "assets/images/milestone_wave.png";
import clsx from "clsx";
import React, { useState } from "react";
import authService from "services/auth.service";
import { useAuthUser } from "store/reducers/auth";
import MenuLinks from "./menuLinks";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    backgroundImage: `url(${Background})`,
    backgroundPosition: "46% bottom",
    backgroundRepeat: "no-repeat",
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  logoSize: {
    height: "40px"
  }
}));

export default function PersistentDrawerLeft() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [hideWhiteLogo, setHideWhiteLogo] = useState(false);

  const authUser = useAuthUser();

  const handleDrawerOpen = () => {
    setHideWhiteLogo(true);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setHideWhiteLogo(false);
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}>
        <Toolbar>
          {authService.isAuthenticated() && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}>
              <MenuIcon />
            </IconButton>
          )}

          {!hideWhiteLogo && (
            <Box flexGrow={1}>
              <img src={Logo} alt="Logo da Vibbra" className={classes.logoSize} />
            </Box>
          )}

          {hideWhiteLogo && <Box flexGrow={1} />}
        </Toolbar>
      </AppBar>

      {authService.isAuthenticated() && ((authUser.on_boarding_status === "complete" || authUser.on_boarding_status === "release")
        || (authUser.choice === "freelancer" || ['scheduled_interview', 'ready_to_activate', 'active'].includes(authUser.real_status))) && (
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}>
          <div className={classes.drawerHeader}>
            <Box display="flex">
              <Box flexGrow={1} ml={3} pt={2} mb={1}>
                <img src={LogoBlue} alt="Logo da Vibbra" className={classes.logoSize} />
              </Box>
              <Box pt={2}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? <MenuIcon color="primary" /> : <MenuIcon color="primary" />}
                </IconButton>
              </Box>
            </Box>
          </div>

          <MenuLinks />
        </Drawer>
      )}
    </div>
  );
}
