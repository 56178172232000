import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

export default function UpdateTypeBankAccountModalView({ authUpdateTypeBankAccountModal, handleClose, goToEditProfile }) {
  return (
    <Dialog open={authUpdateTypeBankAccountModal.open} onClose={handleClose}>
      <DialogTitle>Lembrete</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>Por favor, atualize seu tipo de conta bancária!</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={goToEditProfile} autoFocus>
          Editar perfil
        </Button>
      </DialogActions>
    </Dialog>
  );
}
