import React, { createContext, useState, useContext } from 'react'

const StepContext = createContext();

export default function StepProvider({ children }) {
  const [step, setStep] = useState('0')

  return <StepContext.Provider value={{step, setStep}}>{children}</StepContext.Provider>
}

export function useStep() {
  const context = useContext(StepContext)
  const { step, setStep } = context
  return { step, setStep }
}