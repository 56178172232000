import * as Sentry from "@sentry/react";
import React, { createContext, useContext, useState } from "react";
import { useAuthUser } from "store/reducers/auth";

const SystemContext = createContext();

export default function SystemProvider({ children }) {
  const authUser = useAuthUser();

  // option navbar
  const [selectedOption, setSelectedOption] = useState("home");
  // avatar
  const [myAvatar, setMyAvatar] = useState(authUser ? authUser.avatar : "../../assets/avatars/0.svg");
  // alert
  const [showAlert, setShowAlert] = useState(false);
  // navbar menu
  const [navbarMenu, setNavbarMenu] = useState(false);
  // show notifications
  const [showNotifications, setShowNotifications] = useState(true);
  // show alert error
  const [showErrorModal, setShowErrorModal] = useState(false);

  const sentryError = (error) => Sentry.captureException(error);

  return (
    <SystemContext.Provider
      value={{
        selectedOption,
        setSelectedOption,
        myAvatar,
        setMyAvatar,
        showAlert,
        setShowAlert,
        navbarMenu,
        setNavbarMenu,
        showNotifications,
        setShowNotifications,
        showErrorModal,
        setShowErrorModal,
        sentryError
      }}>
      {children}
    </SystemContext.Provider>
  );
}

export function useSystem() {
  const context = useContext(SystemContext);
  const {
    selectedOption,
    setSelectedOption,
    myAvatar,
    setMyAvatar,
    showAlert,
    setShowAlert,
    navbarMenu,
    setNavbarMenu,
    showNotifications,
    setShowNotifications,
    showErrorModal,
    setShowErrorModal,
    sentryError
  } = context;
  return {
    selectedOption,
    setSelectedOption,
    myAvatar,
    setMyAvatar,
    showAlert,
    setShowAlert,
    navbarMenu,
    setNavbarMenu,
    showNotifications,
    setShowNotifications,
    showErrorModal,
    setShowErrorModal,
    sentryError
  };
}
