import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function ActiveUserModalView({ authActiveUserModal, handleClose, handleActiveProfile, loading }) {
  const [dontAskAgain, setDontAskAgain] = React.useState(false);

  const handleChangeDontAskAgain = (event) => setDontAskAgain(event.target.checked);

  return (
    <Dialog open={authActiveUserModal.open} onClose={handleClose}>
      <DialogTitle>Parabéns!</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Parabéns! Você preencheu todos os dados principais do seu perfil e agora a nossa equipe comercial já poderá trabalhar para trazer
          demandas para você, basta ativar o seu perfil!
        </DialogContentText>
        <FormControlLabel
          control={<Checkbox color="primary" checked={dontAskAgain} onChange={handleChangeDontAskAgain} />}
          label="Não perguntar novamente"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(dontAskAgain)} variant="text">
          Agora não
        </Button>
        <Button onClick={handleActiveProfile} disabled={loading} startIcon={loading && <CircularProgress size={18} />} autoFocus>
          {loading ? "Ativando Perfil" : "Ativar Perfil"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
