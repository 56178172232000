import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import vibbrantesService from "services/vibbrantes.service";
import createAction from "store/createAction";
import { AUTH_SET_USER, useAuthAuthenticated } from "store/reducers/auth";
import ActiveUserModal from "_common/components/ActiveUserModal";
import HourAdjustmentModal from "_common/components/HourAdjustmentModal";
import UpdateTypeAccountBankModal from "_common/components/UpdateTypeAccountBankModal";

import theme from "_common/theme";
import NavBar from "./components/navbar";
import SystemProvider from "./context/system";
import Routes from "./routes.js";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const authAuthenticated = useAuthAuthenticated();
  const [appReady, setAppReady] = useState(false);

  const fetchInitialsData = useCallback(async () => {
    if (authAuthenticated) {
      const { data } = await vibbrantesService.getUser();
      if (data.vibbrante_type === "nerd") {
        history.push("/nerd");
      }

      dispatch(createAction(AUTH_SET_USER, data));

      setAppReady(true);
    } else {
      setAppReady(true);
    }
  }, [authAuthenticated, dispatch, history]);

  useEffect(() => {
    fetchInitialsData();
  }, [fetchInitialsData]);

  return appReady ? (
    <Sentry.ErrorBoundary fallback="An error has occurred">
      <ThemeProvider {...{ theme }}>
        <CssBaseline />
        <SystemProvider>
          <NavBar routes={<Routes />} />
        </SystemProvider>
        <ActiveUserModal />
        <HourAdjustmentModal />
        <UpdateTypeAccountBankModal />
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  ) : null;
}

export default App;
