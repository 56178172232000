import React from "react";
import { useDispatch } from "react-redux";
import createAction from "store/createAction";
import { AUTH_SET_HOUR_ADJUSTMENT_MODAL_CLOSE, useAuthHourAdjustmentModal } from "store/reducers/auth";
import HourAdjustmentModalView from "./HourAdjustmentModalView";
import { LOCAL_STORAGE_REMEMBER_LATER_HOUR_ADJUSTMENT, LOCAL_STORAGE_REMINDER_SEEN_HOUR_ADJUSTMENT } from "_common/constants";

export default function HourAdjustmentModal() {
  const dispatch = useDispatch();

  const authHourAdjustmentModal = useAuthHourAdjustmentModal();

  const rememberMeLater = async () => {
    localStorage.setItem(LOCAL_STORAGE_REMINDER_SEEN_HOUR_ADJUSTMENT, new Date());
    localStorage.removeItem(LOCAL_STORAGE_REMEMBER_LATER_HOUR_ADJUSTMENT);
    dispatch(createAction(AUTH_SET_HOUR_ADJUSTMENT_MODAL_CLOSE));
  };

  const handleClose = () => {
    localStorage.setItem(LOCAL_STORAGE_REMINDER_SEEN_HOUR_ADJUSTMENT, new Date());
    localStorage.removeItem(LOCAL_STORAGE_REMEMBER_LATER_HOUR_ADJUSTMENT);
    dispatch(createAction(AUTH_SET_HOUR_ADJUSTMENT_MODAL_CLOSE));
  };

  return <HourAdjustmentModalView {...{ authHourAdjustmentModal, handleClose, rememberMeLater }} />;
}
