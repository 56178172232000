import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import createAction from "store/createAction";
import { AUTH_SET_ACTIVE_USER_MODAL_OPEN, AUTH_SET_HOUR_ADJUSTMENT_MODAL_OPEN, AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_OPEN } from "store/reducers/auth";
import { LOCAL_STORAGE_DONT_ASK_AGAIN_ACTIVE_USER } from "_common/constants";
import YupValidator from "_common/utils/YupValidator";
import differenceInDays from "date-fns/differenceInDays";
import { LOCAL_STORAGE_REMINDER_SEEN_HOUR_ADJUSTMENT } from "_common/constants";
import { isBefore } from "date-fns";

export const schemaProfileValidationFreelancer = YupValidator.object().shape({
    on_boarding_status: YupValidator.string().oneOf(["release"], "Onboarding ainda não foi liberado."),
    about: YupValidator.string().required("Apresentação está em branco.").nullable(),
    vibbrante_skills: YupValidator.array().min(5, "Você cadastrou menos que 5 habilidades."),
    address: YupValidator.string().required("Endereço está em branco.").nullable(),
    address_number: YupValidator.string().required("Número do endereço está em branco.").nullable(),
    address_district: YupValidator.string().required("Bairro está em branco.").nullable(),
    birth_date: YupValidator.string().required("Data de nascimento está em branco.").nullable(),
    cel_phone: YupValidator.string().required("Celular está em branco.").nullable(),
    city: YupValidator.string().required("Cidade está em branco.").nullable(),
    cpf: YupValidator.string().CPFValidation().required("CPF está em branco.").nullable(),
    emergency_contact_name: YupValidator.string().required("Você não adicionou um contato de emergência.").nullable(),
    emergency_contact_phone: YupValidator.string().required("Você não adicionou o telefone do contato de emergência.").nullable(),
    gender: YupValidator.string().required("Você não definiu o sexo.").nullable(),
    linkedin: YupValidator.string().url("A url do seu Linkedin está inválida.").required("O seu linkedin está em branco.").nullable(),
    state: YupValidator.string().required("Estado está em branco.").nullable(),
    zipcode: YupValidator.string().required("CEP está em branco.").nullable(),
    years_experience: YupValidator.number().min(3, "Você não pode ter menos que 3 anos de experiência."),
    real_hour_value: YupValidator.number().min(1, "Você não definiu o seu valor hora."),
    sign_type: YupValidator.string().required("Tipo de login inválido.").nullable(),
    hour_available: YupValidator.number().min(1, "Você precisa definir suas horas disponíveis.").nullable(),
    availability: YupValidator.string().required("Você precisa definir sua disponibilidade.").nullable(),
    english_level: YupValidator.string().required("Você precisa definir seu nível de inglês.").nullable(),
    exp_international_client: YupValidator.string()
        .required("Você precisa informar se possui ou não experiência com clientes do exterior.")
        .nullable()
});

export const schemaProfileValidationVibbrante = YupValidator.object().shape({
    about: YupValidator.string().required("Apresentação está em branco.").nullable(),
    vibbrante_skills: YupValidator.array().min(5, "Você cadastrou menos que 5 habilidades."),
    address: YupValidator.string().required("Endereço está em branco.").nullable(),
    address_number: YupValidator.string().required("Número do endereço está em branco.").nullable(),
    address_district: YupValidator.string().required("Bairro está em branco.").nullable(),
    birth_date: YupValidator.string().required("Data de nascimento está em branco.").nullable(),
    cel_phone: YupValidator.string().required("Celular está em branco.").nullable(),
    city: YupValidator.string().required("Cidade está em branco.").nullable(),
    cpf: YupValidator.string().CPFValidation().required("CPF está em branco.").nullable(),
    emergency_contact_name: YupValidator.string().required("Você não adicionou um contato de emergência.").nullable(),
    emergency_contact_phone: YupValidator.string().required("Você não adicionou o telefone do contato de emergência.").nullable(),
    gender: YupValidator.string().required("Você não definiu o sexo.").nullable(),
    linkedin: YupValidator.string().url("A url do seu Linkedin está inválida.").required("O seu linkedin está em branco.").nullable(),
    state: YupValidator.string().required("Estado está em branco.").nullable(),
    zipcode: YupValidator.string().required("CEP está em branco.").nullable(),
    years_experience: YupValidator.number().min(3, "Você não pode ter menos que 3 anos de experiência."),
    real_hour_value: YupValidator.number().min(1, "Você não definiu o seu valor hora."),
    sign_type: YupValidator.string().required("Tipo de login inválido.").nullable(),
    hour_available: YupValidator.number().min(1, "Você precisa definir suas horas disponíveis.").nullable(),
    availability: YupValidator.string().required("Você precisa definir sua disponibilidade.").nullable(),
    english_level: YupValidator.string().required("Você precisa definir seu nível de inglês.").nullable(),
    exp_international_client: YupValidator.string()
        .required("Você precisa informar se possui ou não experiência com clientes do exterior.")
        .nullable()
});

export default function useCheckProfileComplete(userToValidade) {
    const [profileComplete, setProfileComplete] = useState(false);
    const [profileErrors, setProfileErrors] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        try {
            if (userToValidade) {
                if (userToValidade.vibbrante_type === 'freelancer') schemaProfileValidationFreelancer.validateSync(userToValidade, { abortEarly: false });
                if (userToValidade.vibbrante_type === 'vibbrante') schemaProfileValidationVibbrante.validateSync(userToValidade, { abortEarly: false });
                setProfileComplete(true);
                setProfileErrors([]);

                const dontAskAgainActiveUser = localStorage.getItem(LOCAL_STORAGE_DONT_ASK_AGAIN_ACTIVE_USER);

                if (
                    userToValidade.status === "inactive" &&
                    (!dontAskAgainActiveUser || differenceInDays(new Date(), +dontAskAgainActiveUser) > 5)
                ) {
                    dispatch(createAction(AUTH_SET_ACTIVE_USER_MODAL_OPEN));
                } else if (!userToValidade.type_bank_account && !userToValidade.is_cooperated) {
                    dispatch(createAction(AUTH_UPDATE_TYPE_BANK_ACCOUNT_MODAL_OPEN));
                } else if (userToValidade.vibbrante_type !== "nerd") {
                    let reminderSeenHourAdjustment = localStorage.getItem(LOCAL_STORAGE_REMINDER_SEEN_HOUR_ADJUSTMENT)
                    let reminderSeenHourAdjustmentDate = new Date();
                    if (reminderSeenHourAdjustment) reminderSeenHourAdjustmentDate = new Date(reminderSeenHourAdjustment)
                    let reminderSeenHourAdjustmentDateValidate = new Date(reminderSeenHourAdjustmentDate)
                    reminderSeenHourAdjustmentDateValidate.setMonth(reminderSeenHourAdjustmentDateValidate.getMonth() + 3)

                    if (isBefore(reminderSeenHourAdjustmentDateValidate, new Date())) {
                        dispatch(createAction(AUTH_SET_HOUR_ADJUSTMENT_MODAL_OPEN));
                    }
                }
            }
        } catch (error) {
            setProfileComplete(false);
            setProfileErrors(error.errors);
        }
    }, [dispatch, userToValidade]);

    return { profileComplete, profileErrors };
}