export default {
  htmlFontSize: 14,
  lineHeight: "13px",
  color: "#02284B",
  fontFamily: ["Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"].join(","),
  h2: {
    fontSize: "3.33rem",
    lineHeight: "1.92rem",
    fontWeight: "bold"
  },
  h3: {
    fontSize: "2.66rem",
    lineHeight: "1.38rem",
    fontWeight: "bold"
  },
  h5: {
    fontSize: "1.11rem",
    lineHeight: "1.84rem"
  }
};
