import { combineReducers } from "redux";
import auth from "./reducers/auth";
import snackbar from "./reducers/snackbar";

const rootReducer = combineReducers({
  auth,
  snackbar
});

export default rootReducer;
