export default {
  MuiButton: {
    variant: "contained",
    color: "primary"
  }
  // MuiSelect: {
  //   variant: "outlined",
  //   fullWidth: true,
  //   size: "small"
  // },
  // MuiTextField: {
  //   variant: "outlined",
  //   fullWidth: true,
  //   size: "small"
  // },
  // MuiFormControl: {
  //   variant: "outlined",
  //   fullWidth: true,
  //   size: "small"
  // }
};
