import { useDispatch } from "react-redux";
import createAction from "store/createAction";
import { SNACKBAR_OPEN } from "store/reducers/snackbar";

export default function useSnackbar() {
  const dispatch = useDispatch();

  const snackbar = (message) => dispatch(createAction(SNACKBAR_OPEN, message));

  const snackbarSuccess = (message = "Operação efetuada com sucesso!") => dispatch(createAction(SNACKBAR_OPEN, message));

  return { snackbar, snackbarSuccess };
}
